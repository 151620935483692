<template>
  <div class="signup-user" v-if="!isProcessing">
    <signup-form class="signup-user__profile" @set-postparams="setPostParams" @set-blob="setBlob" @set-canchange="setCanChange"
                 @set-nowvalidationstatus="setNowValidationStatus" :validation-status="validationStatus"/>
    <v-btn class="signup-user__btn" depressed :disabled="!canChange" @click="registrateUser()">あなたの情報を登録する</v-btn>
  </div>
</template>
<script>
import signupForm from '@/components/common/form/user.vue'

export default {
  name: 'signup-user',
  components: { signupForm },
  data () {
    return {
      postParams: {
        // プロフィール画像
        icon: '',
        // 名前
        name: '',
        // 会社名・肩書
        position: '',
        // プロフィール
        profile: '',
        // URL
        link: ''
      },
      // 各入力項目のバリデーション結果
      validationStatus: {
        // プロフィール画像
        // before: 初期状態 or 更新ボタンが押されたタイミングのバリデーション結果
        // now: 現在の入力値に対するバリデーション結果
        icon: {
          before: '',
          now: ''
        },
        // 名前
        name: {
          before: '',
          now: ''
        },
        // 会社名・肩書
        position: {
          before: '',
          now: ''
        },
        // プロフィール
        profile: {
          before: '',
          now: ''
        },
        // URL
        link: {
          before: '',
          now: ''
        }
      },
      // Blobファイル
      blob: null,
      // 画像の格納先のパス名
      path: '',
      // 登録可能ボタンが押せるかどうか
      canChange: false
    }
  },
  async mounted () {
    // 入力項目の検証結果の初期化
    Object.keys(this.validationStatus).forEach(key => this.validationStatus[key].before = this.VALIDATION_DEFINITION['valid'])

    const promises = []

    // 例外情報の取得
    if (!this.isGotExemption) promises.push(this.$store.dispatch('exemptions/getExemption', this.uid))

    // 限定グループ名が未取得なら取得
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Boolean} 例外情報取得済みかどうか
     */
    isGotExemption () {
      return this.$store.getters['exemptions/isGot']
    },
    /**
     * @return {Object} 例外情報
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    },
    /**
     * @return {Boolean} 限定グループ設定の状態
     */
    isSetGroupSetting () {
      return this.$store.getters['settingGroup/isSetGroupSetting']
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {Object} ユーザー一覧
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Boolean} 名前が使用済みかどうか
     */
    isUsedName () {
      // 自分のuidのObjectを除去
      const { [this.uid]: {}, ...targetUsers } = this.users
      return Object.keys(targetUsers).some( uid => targetUsers[uid].name === this.postParams.name)
    },
    /**
     * @return {Object} バリデーション結果の定義
     */
    VALIDATION_DEFINITION () {
      return this.$store.getters.VALIDATION_DEFINITION
    }
  },
  methods: {
    /**
     * ユーザー情報の登録
     */
    async registrateUser () {
      this.$store.commit('setSubmitting', true)

      // 入力項目毎のバリデーション結果を更新する
      this.updateValidationStatus()

      const validationStatusSummary = this.validInputValues()
      // 入力項目のなかに無効な値があるときはその状態に応じてテロップを出して登録処理をやめる
      if (validationStatusSummary !== this.VALIDATION_DEFINITION['valid']) {
        if (validationStatusSummary === this.VALIDATION_DEFINITION['requiredBlank']) {
          this.$store.commit('setTelop', { show: true, msg: '入力必須な項目で未入力な箇所があります。', type: 'error' })
        } else {
          this.$store.commit('setTelop', { show: true, msg: '入力された項目に不正な値があります。', type: 'error' })
        }
        this.$store.commit('setSubmitting', false)
        return
      }

      if (this.postParams.name) {
        // 全ユーザーを取得
        await this.$store.dispatch('users/getUsers')

        // ユーザー名が既に使用されているかどうかの確認
        if (this.isUsedName) {
          this.$store.commit('setTelop', { show: true, msg: 'すでに登録されている名前です。\n別の名前を記入して下さい。', type: 'error' })
          this.$store.commit('setSubmitting', false)
          return
        }
      }

      // 画像のアップロード
      const url = await this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blob, path: this.path })
      this.postParams.icon = url

      // ユーザー情報に作成時間と変更時間等の情報追加
      this.postParams.createdAt = new Date()
      this.postParams.updatedAt = new Date()
      this.postParams.isFacebookMember = false
      this.postParams.isDeleted = false
      this.postParams.authority = 'member'

      // ユーザー情報の更新
      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: this.postParams })

      // 例外情報の有無で分岐
      if (this.exemption) {
        // 例外情報があるなら登録完了メールを送信して限定グループの設定有無で遷移先を変える
        this.$store.dispatch('functions/sendEventEmail', 'registratedExemptionUser')
        if (this.isSetGroupSetting) {
          this.$router.push({ name: 'signup_group' })
        } else {
          this.$router.push({ name: 'signup_done' })
        }
      } else {
      // 例外情報がないならSignupCard画面へ遷移する
        this.$router.push({ name: 'signup_card' })
      }
    },
    /**
     * 入力値全体が有効な値かどうか検証する
     * @return {String} VALIDATION_STATUSのいずれかの値
     */
    validInputValues () {
      // 必須項目チェックは別パターンのテロップを出すので優先的にチェックする
      const requiredKeys = ['icon', 'name']
      const setRequiredValues = requiredKeys.every(key => this.validationStatus[key].now !== this.VALIDATION_DEFINITION['requiredBlank'])
      if (!setRequiredValues) {
        return this.VALIDATION_DEFINITION['requiredBlank']
      }

      // 現在の入力値に対して無効な値がないかを検証し, 存在したら最初に見つかった無効な理由を返却する
      const invalidStatusList = Object.keys(this.validationStatus).filter(key => this.validationStatus[key].now !== this.VALIDATION_DEFINITION['valid']).map(key => this.validationStatus[key].now)
      return invalidStatusList.length > 0 ? invalidStatusList[0] : this.VALIDATION_DEFINITION['valid']
    },
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * Blobファイルの代入
     * @param {Blob} blob 格納するBlobファイル
     * @param {String} path 格納する画像の格納先のパス名
     */
    setBlob (blob, path) {
      this.blob = blob
      this.path = path
    },
    /**
     * 登録するボタンが押せるかどうかの結果をセットする. 初回登録時は常にtrue.
     * @param {Boolean} isChanged 初期入力値から変更されたかどうか
     */
    setCanChange (isChanged) {
      this.canChange = isChanged
    },
    /**
     * 現在入力されている値が有効な値かどうかの結果をセットする
     * @param {String} key 入力項目名
     * @param {String} validationStatus バリデーション結果
     */
    setNowValidationStatus (key, validationStatus) {
      this.validationStatus[key].now = validationStatus
    },
    /**
     * 各入力項目のバリデーション結果を更新する
     */
    updateValidationStatus () {
      Object.keys(this.postParams).forEach(key => this.validationStatus[key].before = this.validationStatus[key].now)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup-user {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width 0;
  margin: $header_height auto 0;
  &__text {
    margin: 0;
    font-size: 1.2rem;
  }
  &__profile {
    margin-top: 20px;
  }
  &__btn {
    display: block;
    margin: 60px auto 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      width: auto;
      height: auto;
      padding: 15px 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $concept_transparent_color !important;
    }
  }
}
</style>
